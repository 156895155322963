/**
 * @ignore
 * @internal
 */
export enum TweakerPriority {
  Model,
  Array,
  PlainObject,
  Frozen,
}
