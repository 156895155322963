/**
 * @ignore
 * @internal
 */
export enum SnapshotterAndReconcilerPriority {
  Array,
  Frozen,
  Model,
  PlainObject,
}
